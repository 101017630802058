import { h, render } from "preact"
import { useEffect, useState, useRef } from "preact/hooks"
import { createPortal } from "preact/compat"

export const initSearch = () => {
  const wrapper = document.querySelector("[data-search-overlay]")
  if (wrapper) {
    const action = wrapper.getAttribute("data-search-action")
    render(<SearchComponent action={action} />, wrapper)
  }
}

const SearchComponent = ({ action }) => {
  const dialog = useRef(null)
  const [value, setValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [response, setResponse] = useState(null)
  const loadResults = async (query) => {
    const response = await fetch(`${action}?query=${encodeURIComponent(query)}`)
    if (response.ok) {
      const newResponse = await response.text()
      if (newResponse.replace(/\s/g, "").length) {
        setResponse(newResponse)
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("search-open")
    } else {
      document.body.classList.remove("search-open")
    }
  }, [isOpen])

  const debouncedLoadResults = debounce(loadResults, 200)

  const onInput = (e) => {
    if (e.target.value) {
      debouncedLoadResults(e.target.value)
    }
  }

  const onFocus = (e) => {
    console.log("Clicked in", e)
  }

  const onClose = (e) => {
    e.preventDefault()
    dialog.current.classList.add("closing")
  }

  const onOpen = (e) => {
    e.preventDefault()
    dialog.current.classList.add("opening")
    setIsOpen(true)
  }

  const onAnimationEnd = (e) => {
    if (e.animationName === "show") {
      dialog.current.classList.remove("opening")
      // setIsOpen(true)
    }

    if (e.animationName === "hide") {
      dialog.current.classList.remove("closing")
      setIsOpen(false)
    }
  }

  return (
    <>
      <dialog
        ref={dialog}
        class="search"
        open={isOpen}
        onClose={onClose}
        onAnimationEnd={onAnimationEnd}
      >
        <div class="container">
          <div class="search__header">
            <input
              class="search__input"
              name="query"
              type="text"
              value={value}
              autofocus
              onInput={(e) => {
                setValue(e.target.value)
                onInput(e)
              }}
              onFocus={onFocus}
              placeholder="Was suchen Sie?"
            />
            <button class="search__close button button--icon" onClick={onClose}>
              <svg
                class="icon"
                aria-label="{% translate 'Close Search Overlay' %}"
                role="img"
              >
                <use xlinkHref="#icon-close" />
              </svg>
            </button>
          </div>
          <div
            class="search__results articles grid grid--gapped grid--reveal"
            dangerouslySetInnerHTML={{ __html: response }}
          />
        </div>
      </dialog>
      <SearchButton onOpen={onOpen} />
      <SearchButtonMobile onOpen={onOpen} />
    </>
  )
}

function debounce(func, wait, immediate) {
  let timeout
  return function () {
    const args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      if (!immediate) func.apply(this, args)
    }, wait)
    if (immediate && !timeout) func.apply(this, args)
  }
}

const SearchButton = ({ onOpen }) => {
  return createPortal(
    <button class="button button--icon" onClick={onOpen}>
      <svg
        class="icon"
        aria-label="{% translate 'Open Search Overlay' %}"
        role="img"
      >
        <use xlinkHref="#icon-search" />
      </svg>
    </button>,
    document.querySelector("[data-search-toggle]"),
  )
}

const SearchButtonMobile = ({ onOpen }) => {
  return createPortal(
    <button class="button button--icon" onClick={onOpen}>
      <svg
        class="icon"
        aria-label="{% translate 'Open Search Overlay' %}"
        role="img"
      >
        <use xlinkHref="#icon-search" />
      </svg>
    </button>,
    document.querySelector("[data-search-toggle-mobile]"),
  )
}
