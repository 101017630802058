export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export function qs(selector, base = document) {
  return base.querySelector(selector)
}

export function qsa(selector, base = document) {
  return base.querySelectorAll(selector)
}
