import { onReady, qsa } from "./utils.js"

export function initMasonry() {
  if (CSS.supports("grid-template-rows", "masonry")) {
    /* Nice, we're done */
    return
  }

  const resize = () => {
    for (const parent of qsa(".masonry")) {
      const style = getComputedStyle(parent)
      const gap = Number.parseInt(style.gridRowGap, 10)
      const height = Number.parseInt(style.gridAutoRows, 10) + gap

      for (const cell of parent.children) {
        if (cell.matches(".cell")) {
          let cellHeight = gap
          for (const child of cell.children) {
            cellHeight += child.getBoundingClientRect().height
          }
          const span = Math.ceil(cellHeight / height)
          cell.style.gridRowEnd = `span ${span}`
        }
      }
    }
  }

  onReady(resize)
  window.addEventListener("load", resize)
  window.addEventListener("resize", resize)
}
