import { qsa, qs } from "./utils.js"

let textareas = []
let hiddenFields = []
let overlay
let selectWrapper
let newsletterCheckbox
let turnstileWidgets = []

/**
 * Set innerHTML for an element and ensure script tags are properly re-executed.
 * @param {HTMLElement} elm - The element to set HTML for.
 * @param {string} html - The HTML string to set as the element's content.
 */
const setInnerHTML = (elm, html) => {
  elm.innerHTML = html
  for (const oldScriptEl of qsa("script", elm)) {
    const newScriptEl = document.createElement("script")
    for (const attr of oldScriptEl.attributes) {
      newScriptEl.setAttribute(attr.name, attr.value)
    }

    const scriptText = document.createTextNode(oldScriptEl.innerHTML)
    newScriptEl.appendChild(scriptText)

    oldScriptEl.replaceWith(newScriptEl)
  }
}

/**
 * Show the overlay with the provided HTML content.
 * @param {string} html - The HTML content to display in the overlay.
 */
const showOverlay = (html) => {
  if (!overlay) {
    overlay = document.createElement("div")
    overlay.className = "overlay"
    document.body.appendChild(overlay)
    document.body.style.overflow = "hidden" // Prevent body from scrolling
  } else {
    cleanupEventHandlers()
  }
  setInnerHTML(overlay, html)
  attachEventHandlers()
}

/**
 * Resize a textarea to fit its content.
 * @param {HTMLTextAreaElement} textarea - The textarea element to resize.
 */
const resizeTextarea = (textarea) => {
  textarea.style.height = "auto"
  textarea.style.height = `${textarea.scrollHeight}px`
}

/**
 * Attach form submit handlers to forms and input handlers to textareas within the overlay.
 * Also attach handlers for image fields and newsletter checkboxes.
 */
const attachEventHandlers = () => {
  for (const form of qsa("form", overlay)) {
    const submitHandler = async (e) => {
      e.preventDefault()
      const body = new FormData(form)
      try {
        const response = await fetch(form.action, {
          method: "POST",
          credentials: "include",
          body,
        })
        const responseText = await response.text()
        cleanupEventHandlers() // Remove event listeners after successful submit
        showOverlay(responseText)
      } catch (error) {
        console.error("Form submission failed:", error)
      }
    }
    form.submitHandler = submitHandler
    form.addEventListener("submit", submitHandler)
  }

  textareas = qsa("textarea", overlay)
  for (const textarea of textareas) {
    const inputHandler = () => resizeTextarea(textarea)
    textarea.inputHandler = inputHandler
    textarea.addEventListener("input", inputHandler)
    resizeTextarea(textarea) // Initial resize
  }

  // Image field and checkbox handlers
  selectWrapper = qs("[data-display-image]", overlay)
  hiddenFields = qsa("[data-if-newsletter]", overlay)
  newsletterCheckbox = qs("#id_newsletter", overlay)

  if (selectWrapper) {
    const img = qs("img", selectWrapper)
    const select = qs('input[type="file"]', selectWrapper)

    const selectHandler = (event) => {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target.result
          if (file.type.startsWith("image/")) {
            img.src = content
            img.classList.remove("hide")
          } else {
            img.classList.add("hide")
            img.src = ""
          }
        }
        reader.readAsDataURL(file)
        qs("label", selectWrapper).textContent = "Bild ändern"
        selectWrapper.classList.add("form__1-3")
        selectWrapper.classList.remove("form__2-2")
      }
    }

    select.addEventListener("change", selectHandler)
    select.selectHandler = selectHandler
  }

  if (newsletterCheckbox) {
    const showDependentFields = (show) => {
      for (const field of hiddenFields) {
        field.classList.toggle("hide", !show)
        const inputs = qsa("input, textarea, select", field)
        for (const input of inputs) {
          if (show) {
            input.setAttribute("required", "required")
          } else {
            input.removeAttribute("required")
            input.value = ""
          }
        }
      }
    }

    const checkboxHandler = (event) => {
      showDependentFields(event.target.checked)
    }

    newsletterCheckbox.addEventListener("change", checkboxHandler)
    newsletterCheckbox.checkboxHandler = checkboxHandler

    if (newsletterCheckbox.checked) {
      showDependentFields(true)
    }
  }

  // Initialize Turnstile widgets if available
  if (typeof turnstile !== "undefined") {
    turnstileWidgets = []
    const turnstileElements = qsa(".cf-turnstile", overlay)
    for (const widget of turnstileElements) {
      const renderedWidget = turnstile.render(widget, {
        sitekey: widget.getAttribute("data-sitekey"),
      })
      turnstileWidgets.push(renderedWidget)
    }
  }
}

/**
 * Remove event listeners and reset global variables.
 */
const cleanupEventHandlers = () => {
  // Remove event listeners on forms
  for (const form of qsa("form", overlay)) {
    if (form.submitHandler) {
      form.removeEventListener("submit", form.submitHandler)
      form.submitHandler = undefined
    }
  }

  // Remove event listeners on textareas
  for (const textarea of textareas) {
    if (textarea.inputHandler) {
      textarea.removeEventListener("input", textarea.inputHandler)
      textarea.inputHandler = undefined
    }
  }

  // Remove event listeners on image field and newsletter checkbox
  if (selectWrapper) {
    const select = qs('input[type="file"]', selectWrapper)
    if (select?.selectHandler) {
      select.removeEventListener("change", select.selectHandler)
      select.selectHandler = undefined
    }
  }

  if (newsletterCheckbox?.checkboxHandler) {
    newsletterCheckbox.removeEventListener(
      "change",
      newsletterCheckbox.checkboxHandler,
    )
    newsletterCheckbox.checkboxHandler = undefined
  }

  // Remove Turnstile widgets if available
  if (typeof turnstile !== "undefined") {
    for (const widget of turnstileWidgets) {
      turnstile.remove(widget)
    }
    turnstileWidgets = []
  }

  textareas = []
  selectWrapper = null
  hiddenFields = []
  newsletterCheckbox = null
}

/**
 * Hide and remove the overlay.
 */
const hideOverlay = () => {
  const reload = qs("[data-reload]")
  if (overlay) {
    cleanupEventHandlers()
    overlay.remove()
    overlay = null
    document.body.style.overflow = "" // Allow body to scroll again

    if (reload) window.location.reload()
  }
}

/**
 * Fetch and display overlay content from a given URL.
 * @param {string} url - The URL to fetch the overlay content from.
 */
const fetchOverlayContent = async (url) => {
  try {
    const response = await fetch(url)
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
    const html = await response.text()
    showOverlay(html)
  } catch (error) {
    console.error("Failed to fetch overlay content:", error)
  }
}

/**
 * Initialize event listeners for overlay form interactions.
 */
export const initOverlayForms = () => {
  document.body.addEventListener("click", (e) => {
    let btn = e.target.closest("[data-subscribe]")
    if (btn) {
      e.preventDefault()
      fetchOverlayContent(btn.dataset.subscribe)
    }

    btn = e.target.closest("[data-close-overlay]")
    if (btn) {
      e.preventDefault()
      hideOverlay()
    }
  })

  document.body.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      hideOverlay()
    }
  })
}
